import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {App} from "./App";
import "react-datetime/css/react-datetime.css";

Sentry.init({
	dsn:
		"https://a6d7ad967116479aa1dbee8ab98868da@o537203.ingest.sentry.io/5655352",
	integrations: [new Integrations.BrowserTracing()],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
});

export const history = createBrowserHistory();

const onRedirectCallback = (appState: any) => {
	// Use the router's history module to replace the url
	history.replace(appState?.returnTo || window.location.pathname);
};
ReactDOM.render(
	<React.StrictMode>
		<Auth0Provider
			domain="canvass.eu.auth0.com"
			clientId="kdBmtXNjKglIrUovkZyPHn1jaKR50DrW"
			redirectUri={process.env.REACT_APP_AUTH_REDIRECT_URL}
			audience={process.env.REACT_APP_API_AUDIENCE}
			onRedirectCallback={onRedirectCallback}
		>
			<App />
		</Auth0Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
