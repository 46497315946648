import React, { FunctionComponent, lazy, useState } from "react";
import {
  Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  AccountStateContext,
  IUserState,
  userStateInitialValue,
} from "../contexts/accountStateContext";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Loader from "../components/ui/loader";
const Auth = lazy(() => import("../layouts/Auth"));
const Admin = lazy(() => import("../layouts/Admin"));

interface Props {}

interface IProps {
  path: string;
  component: React.ComponentType<Props>;
  // any other props that come into the component
}

export const history = createBrowserHistory();
const PrivateRoute = ({ path, component, ...args }: IProps) => (
  <Route
    path={path}
		component={withAuthenticationRequired(component, {
			// Show a message while the user waits to be redirected to the login page.
			onRedirecting: () => <Loader />,
		})}
		{...args}
	/>
);
export const Content: FunctionComponent = () => {
  const [accountState, setAccountState] = useState<IUserState>(
    userStateInitialValue
  );

  const saveUser = (user: IUserState) => setAccountState(user);

  return (
    <AccountStateContext.Provider
      value={{ user: accountState, saveUser: saveUser }}
    >
      <ToastContainer />
      <Router history={history}>
        <Switch>
          <PrivateRoute path="/admin" component={Admin} />
          {/* <Route path="/admin" render={(props) => <Admin {...props} />} /> */}
          <Route path="/auth" render={(props) => <Auth {...props} />} />
          <Redirect to="/auth" />
        </Switch>
      </Router>
    </AccountStateContext.Provider>
  );
};
