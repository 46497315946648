import { createContext } from "react";

type TUserType = "USER" | "ADMIN" | "SUPER_ADMIN";

export interface IUserState {
	id: String;
	first_name: String;
	last_name: String;
	other_name?: String;
	email: String;
	type: TUserType;
}

export interface IAccountStateContext {
	user: IUserState;
	saveUser: (user: IUserState) => void;
}

export const userStateInitialValue: IUserState = {
	id: "",
	first_name: "",
	last_name: "",
	other_name: "",
	email: "",
	type: "USER",
};

export const accountStateInitialValue = {
	user: userStateInitialValue,
	saveUser: (user: IUserState) => {},
};

export const AccountStateContext = createContext<IAccountStateContext>(
	accountStateInitialValue
);
