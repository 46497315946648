import React from "react";

const Loader: React.FC = () => (
  <div className="holder auth">
    <div className="loader">
      <div className="inner one"></div>
      <div className="inner two"></div>
      <div className="inner three"></div>
    </div>
  </div>
);

export default Loader;
